import React, { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { isEmpty } from 'lodash';
import styles from './styles.module.scss';
import { queryQuestionList, isShowQuestion } from '@/api/home';
import { HOMEWORK_URL } from '@/utils/env';
import ParentalVerification from '@/pageComponents/Autonomy/ClassPreparation/ParentalVerification';
import useUser from '@/store/useUser';
import useTraceEventWithAccount from '@/utils/hooks/useTraceEventWithAccount';

type Question = {
  count: number;
  subject: 'MATH' | 'ENGLISH' | 'CHINESE';
};

const subjectMap = new Map([
  ['MATH', '数学'],
  ['ENGLISH', '英语'],
  ['CHINESE', '中文'],
]);

interface Props {
  uuid: string | undefined;
}

const WrongQuestion: FC<Props> = ({ uuid }) => {
  const { t, i18n } = useTranslation('home');
  const [questions, setQuestions] = React.useState<Question[]>([]);
  const [allow, setAllow] = React.useState<boolean>(false);
  const [showVerificationModal, setShowVerificationModal] = React.useState<boolean>(false);
  const { isCompletedParentalVerification } = useUser();
  const { traceEventWithAccount } = useTraceEventWithAccount();

  const getQuestions = React.useCallback(async () => {
    const data = await queryQuestionList();
    setQuestions(data);
  }, []);

  const isPermission = React.useCallback(async () => {
    const data = await isShowQuestion();
    if (data) {
      getQuestions();
    }
    setAllow(data);
  }, [getQuestions]);

  React.useEffect(() => {
    isPermission();
  }, [isPermission, uuid]);

  if (!allow) {
    return null;
  }

  if (isEmpty(questions)) {
    return null;
  }

  const onEnterNote = (subject: string) => {
    traceEventWithAccount({
      _event: 'U_MyMistake_ToCorrectClick',
      subject: subject,
    });
    if (isCompletedParentalVerification) {
      setShowVerificationModal(true);
      return;
    }
    window.open(`${HOMEWORK_URL()}/${i18n.language}/note?enter=web_home&subject=${subject}`, '_self');
  };

  return (
    <div className={styles['wrong-question']}>
      <h2>{t('错题本-我的错题')}</h2>
      <div className={styles.questions}>
        {questions.map((item, index) => (
          <div className={styles['question-item']} key={`key-${index}`}>
            <div className={styles['question-row']}>
              <span className={`${styles[`p1`]} ${styles[`p1-${i18n.language}`]}`}>
                {t(`错题本-${subjectMap.get(item.subject) || ''}`)}
              </span>
              <p
                className={styles.p2}
                dangerouslySetInnerHTML={{
                  __html: t(`错题本-待订正`, {
                    个数: `<b>${i18n.language === 'zh' ? '' : '&nbsp;'}${item.count}&nbsp;</b>`,
                  }),
                }}
              />
            </div>
            <a onClick={() => onEnterNote(item.subject)} className={styles['correct-btn']}>
              {t('错题本-去订正')}
            </a>
          </div>
        ))}
      </div>
      <ParentalVerification
        open={showVerificationModal}
        onClose={() => {
          setShowVerificationModal(false);
        }}
      />
    </div>
  );
};

export default WrongQuestion;
