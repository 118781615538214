import styles from './style.module.scss';
import { ICourseInfo } from '@/pageComponents/CancelCourse/interface';
import TrialHeader from './Header';
import StepsInfo from './StepsInfo';
import useUser from '@/store/useUser';
import { useEffect, useState } from 'react';
import { checkSubscribe, getTrailList } from '@/api/home';
import { avatorDefault } from './constants';
import { ClassType } from '@/pageComponents/CancelCourse/constants';
import { useTranslation } from 'next-i18next';

const TrialCourse = () => {
  const { curStudent } = useUser();
  const { i18n } = useTranslation('trailCourse');
  const isZh = i18n.language === 'zh';
  const [trialCourseList, setTrialCourseList] = useState<Partial<ICourseInfo>[]>([]);
  const [calendarSubscribed, setCalendarSubscribed] = useState<boolean>(false);
  // 体验课列表数据
  const getTrialCourseLists = async () => {
    const result = await getTrailList();
    const courseList = Object.values(result)?.filter(Boolean);
    const _courseList = courseList?.map((list: any) => {
      const {
        courseSchedule,
        courseScheduleId,
        courseSubject,
        status,
        homeworkCompleted,
        crmReport,
        hasTeachingMaterials,
        showMonitorButton,
        ownerInfo,
      } = list || {};
      const {
        teacher: { fullName, contentUrl, gsName },
        compeleted,
        duration,
        startDateTime,
        endDateTime,
        classType,
        courseSectionRespDTO,
        timeOffset,
        platform,
        homeworkPublish,
        cancelButton,
        courseSectionId,
      } = courseSchedule || {};
      const { sectionNameDisplayEn, sectionNameDisplay, course } = courseSectionRespDTO || {};
      const { courseType } = course || {};
      const classTypeZh = ClassType?.[classType] || '';
      const classTypeEn = classType || '';
      return {
        teacherName: (isZh ? fullName : gsName) || fullName,
        teacherAvator: contentUrl || avatorDefault.src,
        compeleted, // 是否完课, 标记是否展开卡片
        duration, // 课程时长
        classTypeZh, // 课程类型中文
        classTypeEn, // 课程类型英文
        startDateTime, // 课程开始时间
        endDateTime, // 课程结束时间
        sectionNameDisplayEn, // 课程展示名称英文
        sectionNameDisplay, // 课程展示名称中文
        courseScheduleId,
        courseSectionId,
        courseSubject,
        status, // 学生对于课程的状态
        timeOffset, // 距离课程开始倒计时
        platform,
        homeworkCompleted, // 作业完成情况
        homeworkPublish, // 作业发布情况
        crmReport,
        hasTeachingMaterials, // 是否展示预习
        cancelButton, // 取消约课按钮
        showMonitorButton, // 监课按钮
        courseType,
        classType,
        ownerInfo, // 是否需要添加顾问模块, 有值表示需要
      };
    });
    setTrialCourseList(_courseList);
  };

  const getSubscribe = async () => {
    const res = await checkSubscribe();
    setCalendarSubscribed(res);
  };

  useEffect(() => {
    getTrialCourseLists();
    getSubscribe();
  }, [curStudent]);

  return (
    <>
      {trialCourseList?.length > 0 ? (
        <div className="mb-[24px] last:mb-0">
          <div className={styles.trialContainer}>
            <div className={styles.trialCourseContent}>
              <TrialHeader />
              {trialCourseList?.map((course) => {
                return (
                  <StepsInfo key={course?.courseScheduleId} course={course} calendarSubscribed={calendarSubscribed} />
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default TrialCourse;
