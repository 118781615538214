import { ToReserveList } from '@/api/types/home';
import MyLecture from '../MyLecture';
import { useTranslation } from 'next-i18next';

const MyLectureList = ({ myCourseList }: { myCourseList: ToReserveList[] }) => {
  const { t } = useTranslation('home');

  return (
    <div className="overflow-hidden rounded-[12px]">
      <div className="relative bg-[rgba(61,177,115,1)] p-[24px] text-[24px] font-[900] text-white">
        <span className="leading-[29px]">{t('我的课程')}</span>
        {/* <Link href="/my-course">{t('更多课程')}</Link> */}
        <img className="absolute right-[100px] top-0 h-[82px]" src="/imgs/pc/home/my_course_header.png" alt="WuKong" />
      </div>
      <div className="h-[24px] bg-[rgba(61,177,115,1)]" />
      <div className="relative top-[-24px] rounded-[12px] bg-white">
        {myCourseList.map((course) => (
          <MyLecture source="首页" isHome key={course.uuid} {...course} />
        ))}
      </div>
    </div>
  );
};

export default MyLectureList;
